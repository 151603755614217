import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import "@evag/ec-react/dist/main.css";

import { Form } from '@evag/ec-react';

import CampaignFeedback from '../components/CampaignFeedback';
import CampaignForm from '../components/CampaignForm';
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import Img from "gatsby-image";
import SEO from '../components/SEO';
import CountSignatures from '../components/CountSignatures';
import {
  FaFacebook,
  FaFlickr,
  FaInstagram,
  FaTwitter,
  FaYoutube,
} from "react-icons/all";

export default function render({ data }) {
  CampaignForm.channelId = data.site.meta.channel;
  const contact = {
    title: `Deputada Estadual Luciana Genro (PSOL/RS)`,
    addressLine1: "Assembleia Legislativa do Rio Grande do Sul, Gabinete 411",
    addressLine2:
      "Praça Marechal Deodoro, 101 - Porto Alegre/RS - CEP 90010-300",
    email: "contato@lucianagenro.com.br",
    emailUrl: "mailto:contato@lucianagenro.com.br",
    phone: "51 99116-4755",
    phoneUrl: "https://api.whatsapp.com/send?phone=5551992650578&text=Quero%20receber%20o%20kit%20de%20materiais%20em%20apoio%20%C3%A0%20*Lei%20Gustavo%20Amaral%20e%20Gabriel%20Marques*%20(Lei%20das%20C%C3%A2meras%20nas%20fardas%20e%20viaturas%20policiais)",
    facebook: "https://www.facebook.com/LucianaGenroPSOL",
    twitter: "https://twitter.com/lucianagenro",
    instagram: "https://www.instagram.com/lucianagenro/",
    youtube: "https://www.youtube.com/user/LucianaGenroPSOL",
    flicker: "https://flickr.com/photos/lucianagenro/",
  };

  return (
    <Layout>
      <SEO title={data.site.meta.title} />

      <nav>
        <div className="wrap">
          <a
            id="btn-home"
            href="https://lucianagenro.com.br"
            title="Página Inicial"
          >
            <Img
              style={{ width: "30%", marginLeft: "16px" }}
              alt="Deputada Estadual Luciana Genro"
              fluid={data.luciana.childImageSharp.fluid}
            />
          </a>
        </div>
      </nav>

      <Hero
        background={data.fundo.childImageSharp.fluid}
        logo={data.hero.childImageSharp.fluid}
        title={data.site.meta.title}
        description={data.site.meta.description}
        channel={data.site.meta.channel}
      />

      <main className="main">
        <div className="wrap">
        {/* <p>O uso de câmeras nas fardas e viaturas policiais vem mostrando resultados positivos em todo o Brasil. Em São Paulo, onde já está sendo aplicada, a medida reduziu em 80% as mortes em operações policiais após um ano. Também graças às câmeras, as mortes de policiais reduziram na cidade, alcançando o menor número nos últimos 30 anos. </p>

<p>Os policiais que apoiam a proposta alegam que a maioria da corporação, que age dentro da lei, também se beneficiaria. Segundo a Secretaria de Segurança Pública do RS, tanto as equipes da Brigada Militar quanto da Polícia Civil, que já utilizaram os equipamentos em caráter de teste, avaliam que o registro em vídeo das ações trouxe maior segurança aos próprios policiais e também inibiu o comportamento dos indivíduos abordados, a partir do momento em que percebiam que estavam sendo filmados.</p>

<p>No Rio Grande do Sul, estamos na luta para que essa medida seja aplicada como LEI, o que garantiria que um governo individual não possa mudar de ideia e deixar de utilizar as câmeras. Por isso que a deputada Luciana Genro (PSOL) propôs o projeto Gustavo Amaral, que homenageia o jovem engenheiro negro morto durante uma ação da Brigada Militar em 2020. </p>

<p>Além do uso de câmeras nos uniformes de todos os efetivos da segurança pública, a deputada também propõe que as viaturas sejam equipadas com GPS que registrem sem interrupções a localização da unidade. Em 2021, o projeto de Luciana Genro foi à votação na Assembleia e acabou sendo rejeitado pela maioria dos deputados. Mas a deputada reapresentou a proposta em 2022, por acreditar que essa medida representará um grande avanço para a segurança pública do RS. </p>

<p>Para que o projeto da deputada Luciana Genro seja aprovado, precisamos ter maioria dos votos na Assembleia Legislativa, por isso, contamos com a tua ajuda demonstrando apoio à proposta para que os deputados percebam que a população é favorável a esta medida e votem a favor quando ela chegar ao Plenário. </p> */}
<p>A deputada estadual Luciana Genro, líder da Bancada do PSOL na Assembleia Legislativa, reapresentou o projeto de lei que determina a instalação de câmeras corporais em uniformes de policiais e viaturas da segurança pública.</p>

<p>O nome da lei faz alusão a dois jovens  que foram mortos em abordagens policiais. Gustavo Amaral, jovem negro, engenheiro eletricista, foi assassinado em abordagem policial enquanto se deslocava ao trabalho. Gabriel Marques foi encontrado morto, após ser abordado por 3 policiais.</p>

<p>Quer receber materiais em casa para ajudar a ampliar essa campanha? Peça seus materiais físico pelo nosso WhatsApp, <a href="https://api.whatsapp.com/send?phone=5551992650578&text=Quero%20receber%20o%20kit%20de%20materiais%20em%20apoio%20%C3%A0%20*Lei%20Gustavo%20Amaral%20e%20Gabriel%20Marques*%20(Lei%20das%20C%C3%A2meras%20nas%20fardas%20e%20viaturas%20policiais)">clicando aqui.</a></p>

<h4 className="titulolista">Motivos para apoiar a Lei das Câmeras nas fardas e viaturas policiais</h4>

<ul className="listamotivos">
  <li>Menos mortes, mais segurança: Em estados que policiais já utilizam câmeras nas fardas e viaturas, os resultados são positivos. Por exemplo, em 14 meses de uso de câmeras, mortes em ações da Polícia Militar paulista diminuíram 57%. Já no caso das lesões corporais, a queda foi de 63%, conforme pesquisa da FGV.</li>

  <li>Protege as mulheres: Em casos de violência doméstica, por exemplo, a frequência de registro aumentou 67,5% durante o experimento de uso de câmeras, o que sugere que, sem as câmeras, esse tipo de ocorrência muitas vezes deixava de ser reportado ou era registrado sob outras classificações.</li>

  <li>Protege a vida dos policiais: Também em São Paulo, as câmeras já demonstram ser positivas aos policiais. A morte de policiais militares em serviço caiu quase 40% em 2022. Além disso, auxiliam a identificar criminosos e proteger policiais de falsas acusações. </li>

  <li>Para que seja permanente: Os benefícios da utilização de câmeras em fardas e viaturas faz com que muitos estados as adotem por meio de medidas pontuais, como parcerias e projetos. Apesar de um avanço positivo, a medida é precária, já que nada garante sua continuidade no governo seguinte. A aprovação da lei fará com que, no RS, a medida seja respeitada como política permanente.</li>

</ul>
        </div>

<div className="video" align="center">
  
  <iframe width="560" height="315" align="midle" src="https://www.youtube-nocookie.com/embed/i-mkdwqF4cU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
  
  </div>        
  
      </main>

      <div id="sign" className="signatures">
        <div className="wrap">
          <h2 className="signatures-title">Assine a petição</h2>
          <CountSignatures containerClass={"container-unlimited"} invertedColors={true} channel={data.site.meta.channel} />
          <Form
            forms={[
              CampaignForm,
              {
                ...CampaignFeedback,
                title: data.site.meta.title,
                url: data.site.meta.url,
              },
            ]}
          />
        </div>
      </div>


      <footer className="footer">
        <div className="wrap">
          <div className="content">
            <div className="contact">
              <div className="social-icons">
                <ul>
                  <li>
                    <a
                      id="btn-fb"
                      href={contact.facebook}
                      target="_top"
                      title="Contato Luciana Genro no Facebook"
                    >
                      <FaFacebook />
                    </a>
                  </li>
                  <li>
                    <a
                      id="btn-twitter"
                      href={contact.twitter}
                      target="_top"
                      title="Contato Luciana Genro no Twitter"
                    >
                      <FaTwitter />
                    </a>
                  </li>
                  <li>
                    <a
                      id="btn-instagram"
                      href={contact.instagram}
                      target="_top"
                      title="Contato Luciana Genro no Instagram"
                    >
                      <FaInstagram />
                    </a>
                  </li>
                  <li>
                    <a
                      id="btn-youtube"
                      href={contact.youtube}
                      target="_top"
                      title="Contato Luciana Genro no YouTube"
                    >
                      <FaYoutube />
                    </a>
                  </li>
                  <li>
                    <a
                      id="btn-flickr"
                      href={contact.flicker}
                      target="_top"
                      title="Contato Luciana Genro no Flicker"
                    >
                      <FaFlickr />
                    </a>
                  </li>
                </ul>
              </div>
              <p>
                {contact.title}
                <br />
                {contact.addressLine1}
                <br />
                {contact.addressLine2}
              </p>
              <p>
                E-mail:{" "}
                <a id="email" name="email" href={contact.emailUrl}>
                  {contact.email}
                </a>
                <br />
                WhatsApp da Rede Luciana Genro:{" "}
                <a id="phone" name="phone" href={contact.phoneUrl}>
                  {contact.phone}
                </a>
              </p>
              <p>
                Copyleft © 2017-2021 Luciana Genro
                <br />
                O conteúdo deste site, exceto quando proveniente de outras
                fontes ou onde especificado o contrário, está licenciado sob a
                Creative Commons by-sa 3.0 BR.
                <br />
              </p>
              <p>
                <a
                  id="btn-privacidade"
                  href="https://lucianagenro.com.br/politica-de-privacidade"
                >
                  Política de Privacidade
                </a>
              </p>
            </div>
            <div className="logos">
              <Img
                className="logo"
                alt="PSOL"
                fluid={data.psol.childImageSharp.fluid}
              />
              <Img
                className="logo"
                alt="Deputada Estadual Luciana Genro"
                fluid={data.deputada.childImageSharp.fluid}
              />
            </div>
          </div>
        </div>
      </footer>
    </Layout>
  );
}

render.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
        channel
        url
      }
    }

    hero: file(relativePath: { eq: "projeto-camera.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    luciana: file(relativePath: { eq: "lettering_deputada.png" }) {
      childImageSharp {
        fluid(maxWidth: 280) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    fundo: file(relativePath: { eq: "fundo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    psol: file(relativePath: { eq: "logo-psol.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    deputada: file(relativePath: { eq: "deputada.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
